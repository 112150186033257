.modal-header-custom.modal-header {
    padding: 0 1rem;
}
.modal-header-custom .modal-title{
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    margin: 0 0 18px;
    padding: 0;
}


.modal-body-custom p{
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}
.modal-body-custom.modal-body {
    padding: 0 1rem;
}
.modal-body-custom .form-control {
    border-radius: 4px;
}


.modal-footer-custom.modal-footer{
    justify-content: space-between;
    border-top: 0
}
.cannot-provide{
    font-weight: 600;
    font-size: 13px;
    line-height: 29px;
    text-decoration-line: underline;
    color: #747474;
    cursor: pointer;
}
.modal-footer-custom .btn{
    min-width: 190px;
    font-size: 13px;
    line-height: 29px;
    color: #FFFFFF;
    height: 45px;
}