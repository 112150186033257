@import 'variables';

.title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0;
}

.cardTitle {
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  color: #333;
}

.badge {
  border-radius: 1.0rem;
}

.cursorCard{
  cursor: pointer;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.header {
  padding-top: 6px;
}