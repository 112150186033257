@import 'variables';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 2rem;
    height: 2rem;
    color: $gray-400;
    margin: $spacing-sm;
  }

  .details {
    flex-grow: 1;
    line-height: 1.3;

    .meta {
      color: $text-muted;
      font-size: $font-size-sm;
      line-height: 1.8;
    }
  }

  .actions,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: $spacing-sm;
  }
}

.title {
  font-size: 1rem;
}

.left {
  margin-right: $spacing-sm;
}
