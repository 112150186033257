@import 'variables';

.groupWrap {

}

.storyInfo {
  font-size: 20px
}

.shortInfo {
  font-size: 16px
}

.time {
  font-size: 12px
}

.courseDescription {
  font-size: 16px
}
