@import 'variables';

/* Tooltip.css */
.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipTrigger {
    cursor: pointer;
}

.tooltipText {
    position: absolute;
    top: 100%;
    left: 80%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    display: none;
    font-size: 50%;
}

.tooltipContainer:hover .tooltipText {
    display: block;
}