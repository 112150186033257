@import 'variables';
.assignmentInstructions .modal-dialog {
  max-width: 800px;
  .modal-header {
    align-items: center;
    justify-content: flex-start;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  .header-logo {
    max-width: 100px;
    margin-right: 20px;
  }
}
.carouselInner {
  overflow: visible !important;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.editorContainer {
	
  .ql-container {
    font-size: 16px;
    border: 0;
  }

  .ql-editor.ql-blank:before {
    left:30px;
    font-size: 18px;
  }
  .ql-editor {
    padding:30px;
    min-height: 600px;
    max-height: 100vh;
  }
  .ql-toolbar.ql-snow {
    border: 0;
    text-align: center;
    padding: 5% auto; 
  }
}


@include media-breakpoint-down(sm) {
  .assignmentInstructions .modal-dialog {
    width: 90%;
    margin-left:auto;
    margin-right:auto;
  }
  .carouselInner {
    body {
      background:#fff !important;
    }
    .container {
      width: 100%;
    }
  }
  .editorContainer {
    margin-right: 0;
    margin-left: 0;
    border:0;
    padding:0px;
    min-height: 76vh;
    .ql-container {
      font-size: 16px;
    }
  
    .ql-editor.ql-blank:before {
      left:30px;
    }
    .ql-editor {
      padding:30px;
      min-height: 76vh;
    }
  }
  .registrationForm form {
    height: 76vh;
    display: flex;
    flex-direction: column;
    button {
      justify-self: end;
    }
}
}

@include media-breakpoint-up(md) {
  .carouselInner {
    .modal-dialog {
      max-width: 800px !important;
    }
  }
  .assignmentInstructions .modal-dialog {
    width: 80%;
  }
}