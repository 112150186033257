@import 'variables';

.back {
    background: transparent;
    border: 0;
    border-radius: 0;
    border-right: 1px solid var(--catskill);
    color: var(--second-text-color);
    font-size: 16px;
    font-weight: 600;
    // height: auto;
    line-height: 30px;
    margin-right: 24px;
    padding: 0 32px 0 0;
    width: auto;

    // @media (width >=$laptop-sm) {
    //     width: auto;
    //     height: auto;
    //     padding: 0 32px 0 0;
    //     margin-right: 24px;
    //     border: 0;
    //     border-right: 1px solid var(--catskill);
    //     border-radius: 0;
    //     font-weight: 600;
    //     font-size: 16px;
    //     line-height: 30px;
    //     background: transparent;
    //     color: var(--second-text-color);
    // }

    .icon {
        flex-shrink: 0;
        margin-right: 9px;
    }
}

body[dir='rtl'] {
    .back {
        flex-direction: row-reverse;
        border-right: unset;
        border-left: 1px solid var(--catskill);
        padding: 0 0 0 32px;
        margin-left: 24px;
        margin-right: unset;
    }
}