@import 'variables';

body {
  background: #E8F6FF;
}
body,
html {
  font-family: 'Inter', sans-serif !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.clickable {
  cursor: pointer;

  &:hover {
    background: $hover-bg;
  }
}

.field-error {
  margin-top: $spacing-xs;
  color: $danger;
  font-size: 0.875rem;
}

.thumbnail {
  width: 80px;
  height: 80px;
  @include border-radius($card-border-radius);
  overflow: hidden;
}

.short {
  width: 50px;
}

.font-size-sm {
  font-size: $font-size-sm;
}

label {
  font-weight: $font-weight-bold;
  margin-bottom:0.2rem;
}

.form-check label {
  font-weight: $font-weight-normal;
}
.form-control {
  border-radius: 1.25rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.border-radius {
  border-radius: 19px;
}

.btn {
  border-radius: 50px;
}

.modal-header {
  border-bottom: 0;

}
.modal-content {
  box-shadow: 0px 7.56px 47.88px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding:10px;
}
.list-group {
  border-radius: 0;
}
.list-group-item {
  background:#fff;
  margin:4px;
  border:0;
  // border-width: 0 0 1px 0;
  // border-style: solid;
  // border-color: rgb(119, 119, 119);
  border-radius: 24px !important;
}

.card {
  border:0;
  box-shadow: 0px 7.56px 47.88px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    height: 141px;
  
    .card-border-wrapper {
      display: flex;
    }
  }

  &:active,&:focus {
    height: 141px;
  
    .card-border-wrapper {
      display: flex;
    }
  }
}



.card-body {
  padding: 0.3rem !important;
}

.card-border-wrapper {
  background-color: transparent;
  justify-content: center;
  display: none;
  position: relative;
  top: 20px;

  .card-border {
    border-bottom: 6px solid #C86E99;
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
    margin: 0 20% 0 20%;
  }
}

a.course-item {
  color: #6c757d;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #6c757d;
    text-decoration: underline;
  }
}

.coming-soon {
  padding: 20px;
  background-color: #fff;
  text-align: center;

  span {
    font-weight: bold;
  }
}

.back-btn {
  align-items: center;
  border: 1px solid rgba(79,136,179,.04);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  margin-right: 6px;
  padding: 8px;
  width: 32px;
}

.back-btn-btn {
  border: none;
  background: transparent;
}

.p-24 {
  padding: 24px !important;
}

.bg-white {
  background-color: white;
}

.m-0 {
  margin: 0
}

.f-bold {
  font-weight: 600;
  margin: 0px;
}

.class-card {
  margin-bottom: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.mb-30 {
  margin-bottom: 30px;
}
