table {
    width: 100%;

    tbody {
        tr {
            border-bottom: 1px solid #E2E8F0;
        }
        tr:hover {
            background-color: #10B880 !important;
            cursor: pointer;
        }
    }
}

.tdCell {
    padding: 16px !important;
    padding-top: 28px !important;
    padding-bottom: 28px !important;
}

.tdHeader {
    padding: 16px !important;
    border: none;
    &:first-child {
        border-radius: 10px 0 0 0;
    }
    &:last-child {
        border-radius: 0 10px 0px 0;
    }
}