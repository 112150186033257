.playerContainer {
  height: 120px;
  margin-bottom: -2.5rem;
  overflow: hidden;
}

.inputContainer {
  padding: 0px 180px 0px 180px;
  margin: 0px 95px 0px 95px;
}

.customInputGroup {
  background-color: #f2f4f5 !important;
  color: #000 !important;
  font-size: 25px !important;
  text-align: center !important;
}

.inputGroupAppend {
    background-color: #fff !important;
    cursor: pointer;
}

.marginLeft {
  margin-left: 5px;
}
