@import 'variables';

.moduleCard {
    background: var(--white);
    border: 1px solid var(--catskill);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    max-width: 270px;
    padding: 16px;
    transition: border-color .3s;

    small {
        color: var(--mirage)
    }

    h5 {
        color: var(--active-navlink);
        font-size: 14px;

        &.noWrap {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &:hover {
        text-decoration: none;
    }

    .cardImg {
        max-width: 237px;
        max-height: 150px;
        object-fit: cover;
        width: 100%;
    }

    p {
        font-weight: 700;
        font-size: 16px;;
    }
}

.cardTitle {
    color: var(--slate);
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin: 16px 0 4px;
}

.progressBar {
    width: 100%;

    header {
        align-items: center;
        color: var(--slate);
        display: flex;
        font-size: 12px;
        font-weight: 600;
        justify-content: space-between;
        line-height: 20px;
        margin-bottom: 4px;
    }

    .bar {
        background: var(--progress-color);
        border-radius: 8px;
        height: 8px;
        width: 100%;

        .filled {
            background: var(--filled-color);
            border-radius: 8px;
            height: 100%;
            max-width: 100%;
        }
    }
}