@import 'variables';

.preview_user_avatar {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .user_avatar_background {
        width: 384px;
        height: 384px;
        border: 8px solid #E8F6FF;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        svg {
            width: 324px;
            height: 350px;
            bottom: -5px;
            position: relative;

            @media (max-width: $mobile2) {
                width: 155px;
                height: 160px;
            }
        }
    }

    .default_avatar {
        width: 384px;
        height: 384px;
        border: 8px solid #E8F6FF;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        svg {
            width: 384px;
            height: 384px;
            bottom: -5px;
            position: relative;

            @media (max-width: $mobile2) {
                width: 155px;
                height: 160px;
            }
        }
    }
}