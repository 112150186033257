.storyStatus {
    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
    }
}

small {
    
}

