@import 'variables';

.courseSelect {
    overflow-y: visible;
    max-width: 33.33%;
    margin-bottom: 32px;
}

.mainWrapper {
    padding-top: 32px;
}

.gridRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    max-width: 1110px;
    /* Specific width for the grid container */
    margin: 0 auto;

    @media (max-width: $laptop) {
        grid-template-columns: 1fr 1fr;
    }
}

.tblWrapper {
    padding-left: 15px;
    padding-right: 15px;
}