.wrapper {
    background-color: #fff;
    width: 100%;
    padding: 32px;
    margin-bottom: 32px;

    .userCardWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px
    }

    .info {
        display: flex;
        justify-content: space-between;

        .btnWrapper {
            position: relative;
            right: 0px;

            button {
                border-radius: 8px;
                color: #C86E99;
                border-color: #C86E99;

                &:hover {
                    background-color: #fff9f9;
                    border-color: #C86E99;
                }
            }

            img {
                width: 18px;
                height: 18px;
            }
        }
    }
}