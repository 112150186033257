.title {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #333333;
}

.cardTitle {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #333;
}

.cursorCard{
  cursor: pointer;
}

.availableCourses {
  a {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }

  a::before,
  a::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    font-size: 0.9em;
  }

  a::before {
    background-color: #54b3d6;
    height: 2px;
    bottom: 0;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }

  a::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    text-align: center;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #54b3d6;
  }

  a:hover::after {
    transform: translate3d(0, 0, 0);
  }

  a:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }

  a span {
    display: inline-block;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  a:hover span {
    transform: translate3d(-200%, 0, 0);
  }
}