@import 'variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $font-size-sm;
  cursor: pointer;
}

.slider {
  height: 20px;
  width: 40px;
  background: $gray-400;
  border-radius: 10px;
  position: relative;
  margin-right: $spacing-xs;

  .knob {
    height: 16px;
    width: 16px;
    top: 2px;
    left: 2px;
    position: absolute;
    border: 1px solid $gray-600;
    border-radius: 50%;
    background: white;
    transition: left 0.1s ease-in;
  }

  &:hover {
    background: $gray-500;
  }

  &.toggled {
    background: $primary;

    .knob {
      left: 22px;
    }

    &:hover {
      background: lighten($color: $primary, $amount: 5%);
    }
  }
}

.disabled {
  cursor: not-allowed;
  color: $text-muted;

  .slider {
    background: $gray-200;

    &.toggled {
      background: $gray-600;
    }
  }
}
