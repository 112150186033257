@import 'variables';

.container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $zindex-popover;
  padding: $spacing-md;
}

.toast {
  width: 300px;
}
