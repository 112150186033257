@import 'variables';

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.header {
  padding: $spacing-md;
  margin-bottom: 20px !important;
  margin-top: 20px
}

.content {
  padding: $spacing-md;
  background: transparent;
  flex-grow: 1;
  overflow-y: auto;
}

.contentB {
  background: transparent;
    flex-grow: 1;
}

.title {
  padding: 0 $spacing-md;
  height: $navbar-height;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 $navbar-height;

  :global {
    .btn-outline-primary {
      background: transparent;
    }
  }
}

.titleContent {
  font-size: 1.3rem;
  font-weight: bold;
  flex-grow: 1;
}

.breadcrumbs {
  display: none;
}

.smallCopy {
  font-size: 50%;
  background-color: #f0f2fa;
  cursor: pointer;
  padding: 4px 8px;
}

@include media-breakpoint-up(md) {
  .title {
    background: transparent;
    align-items: center;
  }

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    color: $gray-600;
    font-size: $font-size-sm;
    margin-right: $spacing-sm;

    .crumb {
      margin-left: $spacing-xs;
      margin-right: $spacing-xs;
    }
  }
}

.headerB {
  background-color: var(--white);
  width: 100%;
  height: 88px;
  margin-top: 25px;
  padding: 25px 0px 25px 32px;

  .wrap {
    display: flex;
    align-items: center;
  }

  .backBtn {
    color: var(--second-text-color);
    font-size: 1rem;
    font-weight: 500;
    padding-right: 32px;
    cursor: pointer;
  }

  .heading {
    color: var(--active-navlink);
    margin-left: 24px;
    font-size: 1.2rem;
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .head {
    color: var(--active-navlink);
    margin-left: 24px;
    font-size: 1.2rem;
  }

  .btnWrapper {
  }

  .btnStyle {
    border-radius: 80px;
    background-color: #fff;
    padding: 5px, 16px, 5px, 16px;
    border: 2px solid #E2E8F0;
    color: #475569;

    &:hover {
      background-color: #475569;
      color: #fff;
      border: 2px solid #E2E8F0;
    }
  }
}
