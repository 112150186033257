@import 'variables';

.container {
  background: #ffffff;
  min-height: 100vh;
  padding: $spacing-md;
}
.card {
  border: 0 !important;
  background-color: #f8f8f8 !important;
  border-radius: 16px !important;
}
.subheading {
  font-weight: 700;
}
