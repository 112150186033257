@import 'variables';

.container {
  border: 2px dashed $gray-500;
  background: $gray-100;
  padding: $spacing-md;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: $gray-200;
  }

  &.activated {
    border-color: $primary;
  }
}

.placeholder {
  color: $gray-500;
}

.input {
  position: absolute;
  left: -1000px;
}
