@import 'variables';

@each $color, $value in $theme-colors {
  .btn-#{$color} .spinner-border {
    color: color-yiq($value) !important;
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} .spinner-border {
    color: $value !important;
  }
}
