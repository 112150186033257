@import 'variables';

.groupHeader {
  background: $gray-100 !important;
  font-size: $font-size-sm;
}

.more {
  text-align: center;
}
