.sectionInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

  .btnWrap {
        border-radius: 8px;
        color: #C86E99;
        border-color: #C86E99;

        &:first {
            margin-right: 16px;
        }
    
        &:hover {
            background-color: #fff9f9;
            border-color: #C86E99;
            color: #C86E99;
        }
    
        img {
            width: 18px;
            height: 18px;
        }
    }
}

.actionWrap {
    position: absolute;
    background: #fff;
    display: flex;
    flex-direction: column;
    right: 200px;

    .show {
        display: block !important;
    }

    .actionWrapMenu {
        display: none;
        position: absolute;
        list-style-type: none;
        padding: 0;
        margin: 0;
        z-index: 1;
        padding: 4px,0px,4px,0px;
        right: 26px;
    
        li {
            padding: 8px;
            border-bottom: 1px solid #E2E8F0;
        }
    
    }
}

.profile {
    img {
        width: 32px;
        height: 32px;
        background-size: cover;
    }

    small {
        margin-left: 8px;
    }
}

.passwd {
    &:hover {
        text-decoration: underline;
    }
}