.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E2E8F0;
    border-radius: 10px;
    padding: 24px 12px 24px 12px;
    flex-grow: 1;

    .profile {
        img {
            width: 32px;
            height: 32px;
            background-size: cover;
        }
        small {
            margin-left: 8px;
        }
    }
}