$primary: #00b2f7;
$info: #5aaafa;

$highlight-1: #58b098;
$highlight-2: #177e89;
$highlight-3: #ffffff;
$background-1: #f5f5f5;

$brand-colors: (
  'primary': $primary,
  'highlight-1': $highlight-1,
  'highlight-2': $highlight-2,
  'highlight-3': $highlight-3,
  'background-1': $background-1,
);

$theme-colors: $brand-colors;

$navbar-height: 4rem;

@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_variables';

$divider-color: $gray-400;

$spacing-3xs: ($spacer * 0.125);
$spacing-2xs: ($spacer * 0.25);
$spacing-xs: ($spacer * 0.5);
$spacing-sm: ($spacer * 0.75);
$spacing-md: $spacer;
$spacing-lg: ($spacer * 1.5);
$spacing-xl: ($spacer * 2);
$spacing-2xl: ($spacer * 2.5);
$spacing-3xl: ($spacer * 3);

$hover-bg: lighten($primary, 85%);
$selected-bg: $hover-bg; // lighten($primary, 15%);

$enable-validation-icons: false;

// screen sizes
$mobile: 360px;
$mobile-m: 520px;
$mobile2: 767px;
$tablet: 768px;
$laptop: 1024px;
$laptop-sm: 1100px;
$laptop-m: 1280px;
$desktop: 1440px;
$desktop-m: 1680px;
$desktop-l: 1800px;
$full-hd: 1920px;

:root {
  --white: #FFF;
  --lightgray: #F1F5F9;
  --lightgray2: #EAF2F6;
  --lightgray3: #E0E3E6;
  --lightgray4: #F8FAFC;
  --lightgrey5: #CAC9C9;
  --gray: #94A3B8;
  --gray2: #94A3BA;
  --gray3: #778391;
  --gray4: #D7ECFA6E;
  --green: #3A6F79;
  --navcolor: #C0E3FB;
  --navcolor2: #AFDEC2;
  --navcolor3: #E8C5DE;
  --navcolor4: #CFC2EC;
  --navcolor5: #F3E2A3;
  --navcolor6: #262B3A;
  --profilecolor: #0D69AA;
  --navlinkscolor: #4F88B3;
  --navlinkscolor2: #669177;
  --navlinkscolor3: #9B688E;
  --navlinkscolor4: #4F3687;
  --navlinkscolor5: #9D6D49;
  --navlinkscolor6: #FFF;
  --navlinkhoverscolor6: #3A425A;
  --sidebar-title: #3F7DA8;
  --active-navlink: #C86E99;
  --hover-sublink: #EBA8C8;
  --pale-pink: #FFD7EA;
  --background: #E8F6FF;
  --background2: #DEF3E7;
  --background3: #F7EAF3;
  --background4: #ECE3FF;
  --background5: #FFF8DD;
  --background6: #353B4F;
  --content-offset: 24px;
  --primary-aside-color: #D7ECFA;
  --black: #000;
  --slate: #475569;
  --errors: #DB504A;
  --catskill: #E2E8F0;
  --second-text-color: #64748B;
  --progress-color: #E0F2FE;
  --filled-color: #FFCC7F;
  --pickled: #334155;
  --mint: #91DDB0;
  --gum: #10B981;
  --mirage: #1E293B;
  --reject: #FB7185;
  --dusty: #CBD5E1;
  --nitification-background: #E6F5F7;
  --nitification-background2: #FFB3BB;
  --nitification-border2: #FD8491;
  --gentle: #FAFDFF;
  --read-only: #DDD;
  /* stylelint-disable-next-line color-function-notation */
  --shadow: rgba(0, 0, 0, 0.4);
  --golden-retriever: #F59E0B;
  --navy-bg: #96CBF6;
}
