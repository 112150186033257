@import 'variables';

.lang {
    margin-left: auto;
}

.title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: var(--pickled);

    @media (max-width: $laptop-sm) {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 22px;
    }
}

.titleClasses {
    display: block;
    line-height: 30px;
    font-size: 22px;
    color: var(--active-navlink);
    font-weight: 600;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.story_meta {
    display: flex;
    align-items: center;

    @media (max-width: $laptop-sm) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.author_info {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--catskill);
    padding-right: 16px;
    margin-right: 16px;
}

.author_avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    svg {
        width: 40px;
        height: 40px;
    }
}

.author_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: var(--active-navlink);
}

.story_data {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--second-text-color);
}

.story_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.story_heading_not_approved {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding: 30px 0px 0px;

    span {
        background-color: #d6d7da;
        padding: 5px;
    }
}

.cover_img {
    width: 100%;
    height: 260px;
    margin-bottom: 24px;
    border-radius: 8px;
    object-fit: cover;

    @media (min-width: $desktop-m) {
        height: 320px;
    }
}

.text {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 30px;
    color: var(--pickled);
    white-space: pre-wrap;
    padding: 0px 32px 32px;
}

// .gallery {
//     display: grid;
//     grid-template-columns: 1fr;
//     gap: 8px;
//     margin-bottom: 24px;

//     @media ($mobile-m <=width < $laptop-sm) {
//         grid-template-columns: 1fr 1fr;
//         gap: 16px 16px;
//     }

//     @media (min-width: $laptop-sm) {
//         grid-template-columns: 1fr 1fr 1fr;
//         gap: 16px 16px;
//     }
// }

.gal_image {
    width: 100%;
    height: 188px;
    border-radius: 8px;
    object-fit: cover;

    @media (min-width: $desktop-m) {
        height: 250px;
    }
}

.tags span {
    margin-right: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: var(--second-text-color);
}

.story_body[dir='rtl'] {
    .author_info {
        border-right: none;
        border-left: 1px solid var(--catskill);
        padding-right: unset;
        padding-left: 16px;
        margin-left: 16px;
        margin-right: 0;
    }

    .author_avatar {
        margin-right: unset;
        margin-left: 10px;
    }
}

body[dir='rtl'] {
    .lang {
        margin-left: unset;
        margin-right: auto;
    }
}

.video {
    border-radius: 10px;
    margin: 20px 0;
    max-height: 500px;
    object-fit: cover;
    object-position: center;
}

.cover_img_caption {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: var(--second-text-color);
    margin-bottom: 24px;
}

.headerClasses {
    align-items: center;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    display: flex;
    border-radius: 16px 16px 0 0;
    padding: 23px 32px;
    border-bottom: 1px solid var(--catskill);
}

@media (max-width: $laptop-sm) {
    .story_meta {
        flex-direction: column;
        align-items: flex-start;
    }

    .author_info {
        margin-bottom: 8px;
        border: none;
    }

    .story_data {
        padding: 0;
    }

    .story_heading {
        align-items: flex-end;
    }

    .text {
        font-size: 14px;
    }
}