@import 'variables';

.avatar {
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
  border: 2px solid $white;
  margin-right: $spacing-xs;
  margin-top: -$spacing-xs;
  margin-bottom: -$spacing-xs;
}
