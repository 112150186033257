@import 'variables';

.centre {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowImg {
    cursor: pointer;
}

.tblWrapper {
    padding-left: 15px;
    padding-right: 15px;
}

.story {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
    padding: 9px;
    border-radius: 8px;

    &Default {
        @extend .story;
        background: #92A0B5;
    }

    &Warning {
        @extend .story;
        background: #F59E0B;
    }

    &Success {
        @extend .story;
        background: #10B880;
    }
}

section {
    margin: 30px 0 30px 0;
}


