@import 'variables';

.container {
  margin: $spacing-md 0;
}

.title {
  margin-bottom: $spacing-md;
  color: $primary;
  cursor: pointer;
}

.caret {
  width: 15px;
  display: inline-block;
}

.body {
  padding: $spacing-md;
  background: $gray-100;
}
