@import 'variables';

.footer {
  text-align: center;
  font-size: $font-size-sm;
  color: $text-muted;
  margin-top: auto;
  margin-bottom: $spacing-md;
  font-weight: $font-weight-light;
}
