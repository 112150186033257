@import 'variables';

.icon-button {
  padding: $spacing-3xs;
  height: 1.3rem;
  padding: 0;
  line-height: 1.3rem;
  outline: none !important;
  background: none;
  border: none;

  &:not(.inverse) {
    color: $text-muted;
  }

  &.inverse {
    background: $text-muted;
    color: white;
    border-radius: 50%;
  }

  @each $key, $color in $theme-colors {
    &.btn-#{$key} {
      $darker: darken($color, 10%);

      &:not(.inverse) {
        &:hover,
        &.active,
        &:focus {
          color: $color;
          background: none;
          border: none;
        }

        &.active:hover {
          color: $darker;
          background: none;
          border: none;
        }
      }

      &.inverse {
        &:hover,
        &.active,
        &:focus {
          background: $color;
        }

        &.active:hover {
          background: $darker;
        }
      }
    }
  }
}
