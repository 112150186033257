@import 'variables';

.title {
  font-size: 1.2rem;
  margin-bottom: $spacing-md;
}

.container {
  margin-bottom: $spacing-xl;
}
